import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

const themeHeader = () => {
    const $firstVisibleContentSection = $('.content-section')
        .filter((index, element) => $(element).css('display') !== 'none')
        .first();
    if ($firstVisibleContentSection.hasClass('content-section--bg-secondary')) {
        $('.header-nav').addClass('header-nav--inverted');
    }

    $(document).on('click', '.nav-menu--opened', (e) => {
        const $target = $(e.target);
        if ($target.hasClass('nav-menu--opened')) {
            doAction('hideNavMenuOverlay', $target);
        }
    });
};

addAction(INIT, themeHeader);
