import { $, addAction, INIT } from '@situation/setdesign.util';

const arrowMarkup = '<span class="icon icon-right-arrow"><svg><use class="icon-use" xlink:href="#icon-right-arrow"></use></svg></span>';

const appendLabel = (index, element) => {
    const $container = $(element);
    const $link = $container.find('a');
    if (!$link.length) return true;

    $link.append(
        $('<span>', {
            class: 'vc-single-image__link-label',
            html: `${$link.attr('aria-label')}${arrowMarkup}`,
        }),
    );
    return true;
};

const addBottomPadding = (index, container) => {
    const $images = $(container).find('.vc-single-image');
    let $shortestImage = $images.first();
    let shortestImageHeight = $shortestImage.height();
    $images.each((i, img) => {
        const $img = $(img);
        const currentHeight = $img.height();
        if (currentHeight < shortestImageHeight) {
            shortestImageHeight = currentHeight;
            $shortestImage = $img;
        }
    });

    $shortestImage.css('padding-bottom', '20px');
};

addAction(INIT, () => {
    $('.vc-single-image--expand-right').each(appendLabel);
    $('.content-column--inline-images').each(addBottomPadding);
});
