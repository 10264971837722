import { $, addAction, INIT } from '@situation/setdesign.util';

const hasJpg = (src) => !/\.(png|svg)$/.test(src);

const addGradientOverlay = () => {
    const $lazyBg = $('[data-lazy-bg]').filter((i, el) => hasJpg($(el).data('lazyBg')));
    const $lazyImage = $('[data-lazy-image]')
        .filter((i, el) => hasJpg($(el).data('lazyImage').src))
        .wrap($('<span class="gradient-overlay--wrap">'))
        .parent();
    const $vcSingleImage = $('img.vc-single-image__image')
        .filter((i, el) => hasJpg($(el).attr('src')))
        .wrap($('<span class="gradient-overlay--wrap">'))
        .parent();
    const $responsivePicture = $('.responsive-picture')
        .filter((i, el) => hasJpg($(el).find('img').attr('data-src')))
        .wrap($('<span class="gradient-overlay--wrap">'))
        .parent();
    $lazyBg
        .add($lazyImage)
        .add($vcSingleImage)
        .add($responsivePicture)
        .addClass('gradient-overlay');

    $('.gradient-overlay--wrap').each((i, el) => {
        const $container = $(el);
        const $target = $container.find('img, [data-lazy-image]');
        const styleAttr = $target.attr('data-lazy-image')
            ? $target.data('lazyImage').style
            : $target.attr('style');
        if (styleAttr) {
            $container.attr('style', styleAttr);
        }
    });
};

addAction(INIT, addGradientOverlay);
