/* global gsap */
/* global ScrollTrigger */
import {
    $, addAction, applyFilters, LAYOUT, INIT,
} from '@situation/setdesign.util';

const adjustCentering = (_, column) => {
    const $column = $(column);
    $column.css('min-height', '').removeClass('content-column--sticky-centered--show');
    const maxWidth = parseInt($column.css('maxWidth'), 10);
    if (!Number.isNaN(maxWidth) && maxWidth !== 100) {
        const $innerColumn = $column.find('.content-column__inner');
        const columnHeight = $innerColumn.outerHeight();
        const outerHeight = Math.min(
            SetDesign.viewport.height() / 2,
            $column.parents('.content-row').outerHeight() / 2,
        );
        const diff = outerHeight + columnHeight / 2;
        $column
            .css('min-height', `calc(${diff}px - var(--dynamic__header-height, 0px))`)
            .addClass('content-column--sticky-centered--show');
    }
};

const calculateEnd = (scrollTrigger) => {
    const windowHeight = SetDesign.viewport.height() - applyFilters('header-height');
    const contentHeight = $(scrollTrigger.pin).outerHeight() + 140;
    const diff = Math.max(contentHeight - windowHeight, 0);
    return diff ? `bottom bottom+=${diff}` : 'bottom bottom';
};

const initAction = () => {
    const $centeredColumns = $('.content-row.align-items-center .content-column--sticky').addClass(
        'content-column--sticky-centered',
    );
    $centeredColumns.parent('.content-row').removeClass('align-items-center');
    addAction(LAYOUT, () => $centeredColumns.each(adjustCentering));
    $('.content-column--sticky').each((i, el) => {
        const $column = $(el);
        const $row = $column.parents('.content-section');
        gsap.matchMedia().add('(min-width: 768px)', () => {
            ScrollTrigger.create({
                trigger: $row.get(),
                // markers: true,
                pin: $column.find('.content-column__inner').get(),
                start: () => `top top+=${applyFilters('header-height')}`,
                end: calculateEnd,
                pinSpacing: false,
            });
        });
    });
};

addAction(INIT, initAction);
