/* global ScrollTrigger */
import { $, addAction, INIT } from '@situation/setdesign.util';

const easeOutExpo = (x) => (x === 1 ? 1 : 1 - 2 ** (-10 * x));
const maxTime = 800;
const easeRate = 30;
const selectors = [
    '.content-background-image__video__overlay',
    '.content-column__inner',
    '.heading',
    '.spotlight-card',
    '.vc-single-image',
    '.team-member-card',
    '.feature-list li',
];

/* eslint-disable no-param-reassign */
const processBatch = (batch, add) => {
    window.requestAnimationFrame(() => {
        batch.forEach((element, index) => {
            if (add) {
                const delay = Math.round(maxTime * easeOutExpo(index / easeRate));
                if (delay) {
                    element.style.transitionDelay = `${delay}ms`;
                }

                element.classList.add('animate-to');
                element.classList.remove('animate-from');
            } else {
                element.style.transitionDelay = '';
                element.classList.add('animate-from');
                element.classList.remove('animate-to');
            }
        });
    });
};

/* eslint-enable no-param-reassign */
addAction(INIT, () => {
    $(selectors.join(', ')).addClass('animate-from');
    ScrollTrigger.batch(selectors.join(', '), {
        batchMax: 50,
        interval: 0.25,
        onEnter: (batch) => processBatch(batch, true),
        // onLeave: (batch) => processBatch(batch, false),
        // onEnterBack: (batch) => processBatch(batch, true),
        onLeaveBack: (batch) => processBatch(batch, false),
    });
});
